/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap");

@font-face {
  font-family: "SF Collegiate Solid";
  font-style: normal;
  font-weight: normal;
  src: local("SF Collegiate Solid"),
    url("https://firebasestorage.googleapis.com/v0/b/rams-app-22bcc.appspot.com/o/static-resources%2Ffonts%2FSF_Collegiate_Solid.woff?alt=media&token=cf4ba69a-0479-4cdf-9c0c-601d03cf7807")
      format("woff");
}

:root,
:root[mode="md"],
:root[mode="md"] {
  --ion-font-family: "Montserrat", "noto-sans";
  font-family: var(--ion-font-family) !important;
}
ion-item-options {
  ion-button {
    margin-top: auto;
    margin-bottom: auto;
  }
}
.facebook-color {
  --background: #3b5998;
}
ion-toolbar#header {
  --color: white;
}
ion-slides {
  --bullet-background-active: var(--ion-color-primary);
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--ion-color-dark);
}

p {
  font-size: 1em;
  font-weight: 300;
}

.linked {
  cursor: pointer;
}

.centered {
  margin: 0 auto;
}

p.center-text {
  text-align: center;
}

.spacer5 {
  height: 5px;
}
.spacer15 {
  height: 15px;
}
.spacer30 {
  height: 30px;
}
.spacer60 {
  height: 60px;
}

ion-img.full-width-image {
  margin: -16px -16px 30px;
  &.content {
    margin: 16px -16px 16px;
  }
  &.footer {
    margin: 0px -16px -16px;
  }
}

.full-width-div {
  margin: -16px -16px -16px;
  &.content {
    margin: 16px -16px 16px;
  }
  &.footer {
    margin: 0px -16px -16px;
  }
}

ion-header ion-toolbar {
  background-color: var(--ion-color-primary);
  color: #ffffff;
}

ion-badge {
  padding: 6px 10px;
  border-radius: 24px;
}

ion-item.no-padding {
  --inner-padding-start: 0;
  --padding-start: 0;
}

.full-width {
  width: 100%;
}

ion-thumbnail.center-image {
  margin: 0 auto;
}

/*** COMMON ***/
ion-card ion-icon.featuredToggle {
  font-size: 2em;
}

@media (max-width: 960px) {
  ion-buttons {
    flex-wrap: wrap;
    ion-button .buttonText.hide960 {
      display: none;
    }
  }
}

/*** SPLIT PANE ***/
@media (max-width: 768px) {
  #main-content.menu-content-open {
    pointer-events: initial;
  }
}
@media (min-width: 769px) {
  #split-pane {
    --side-width: 300px;
    --side-max-width: 20%;
  }
}

/*** APP FOOTER ***/
#app-footer {
  --background: rgb(20, 20, 20);
}

/*** GENERAL ***/
// .ion-page {
//   /* Concrete wall background */
//   /* 40%*/
//   //background: var(--frontend-background);
//   /* 68% */
//   //background: url(https://firebasestorage.googleapis.com/v0/b/rams-app-22bcc.appspot.com/o/static-resources%2Fimagery%2Fgrunge_concrete_68.png?alt=media&token=456c5115-60b6-4831-9e6d-1de268bee582);
//   /* Full */
//   //background: url(https://firebasestorage.googleapis.com/v0/b/rams-app-22bcc.appspot.com/o/static-resources%2Fimagery%2Fconcrete-wall-tablet.jpg?alt=media&token=1d4ba07a-4196-4129-bda3-dd6d7bee6c2f);
// }

ion-content {
  --background: none;
  &.admin {
    --background: var(--admin-background-color);
  }
}

ion-card.image-only {
  --background: #fff0;
  ion-card-content {
    padding: 0;
  }
}
.card-wrapper {
  .card-button-wrapper {
    text-align: center;
    margin-top: -30px;
  }
  @media (max-width: 400px) {
    .card-button-wrapper {
      margin-top: -50px;
    }
  }
}
ion-list {
  background: none !important;
}
form ion-list ion-item {
  --background: #fff0 !important;
}

/*** AUTH SCREENS ***/
ion-content.auth-screen {
  --background: #var(--ion-color-primary);
  --background: -moz-linear-gradient(
    165deg,
    rgba(202, 38, 44, 1) 0%,
    rgba(119, 28, 32, 1) 100%
  );
  --background: -webkit-linear-gradient(
    165deg,
    rgba(202, 38, 44, 1) 0%,
    rgba(119, 28, 32, 1) 100%
  );
  --background: linear-gradient(
    165deg,
    rgba(202, 38, 44, 1) 0%,
    rgba(119, 28, 32, 1) 100%
  );

  #app-background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    // background-image: url("https://firebasestorage.googleapis.com/v0/b/rams-app-22bcc.appspot.com/o/fanatical-logo-overlay.png?alt=media&token=d62262b2-d2ad-40f3-923d-c4ce5bd67326");
    // background-position: top right;
    // background-size: contain;
    // background-repeat: no-repeat;
    z-index: 0;
  }

  #app-logo {
    position: relative;
    width: 80%;
    max-width: 350px;
    height: 250px;
    margin: 30px auto 30px;
    background-image: url("https://firebasestorage.googleapis.com/v0/b/rams-app-22bcc.appspot.com/o/static-resources%2Flogos%2Framshq_icon.png?alt=media&token=40104906-c72d-424d-9f6e-accbb3109b68");
    background-position: top center;
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 10;
  }

  #content-box {
    z-index: 10;
    position: relative;
    width: 80%;
    max-width: 350px;
    margin: 0 auto;
    .instructions {
      color: #ffffff;
    }
    .instructions.small {
      font-size: 0.8em;
    }
    .instructions.faded {
      color: #757575;
    }
    .instructions.bold {
      font-weight: 500;
    }
    .error-message {
      color: #ffffff;
    }
    .guest-button {
      margin: 0 auto;
      color: #ffffff;
      display: inline;
    }
    .social-icons {
      display: inline-block;
      .SSO-icons {
        --color: #ffffff;
        height: 40px;
        ion-icon {
          font-size: 40px;
          --color: #ffffff;
          color: #ffffff;
          margin: 0;
        }
      }
    }
  }
}

/*** CART & CHECKOUT ***/
.cart-content {
  .card {
    background: var(--ion-color-light);
    border-radius: 5px;
    ion-item {
      --background: transparent;
      ion-thumbnail {
        width: 130px;
        height: 130px;
        margin-right: 10px;
      }
      ion-label {
        .text-para {
          font-size: 12px;
          color: var(--ion-color-dark);
        }
        .text-cat-name {
          font-size: 10px;
        }
      }
    }
  }
  .product-actions {
    margin-top: 10px;
    display: flex;
    border-radius: 4px;
    width: 110px;
    margin-left: auto;
    ion-button {
      --color: #var(--ion-color-medium);
      --color-activated: var(--ion-color-dark);
      border-radius: 100%;
      border: solid 1px var(--ion-color-dark);
      height: 30px;
      width: 30px;
    }
    .para-count {
      align-self: center;
      margin: 0 10px;
      color: var(--ion-color-dark);
    }
    .btn-minus {
      margin: 0;
    }
    .btn-add {
      margin: 0;
    }
  }
  .div-price {
    display: flex;
    .para-d-price {
      font-size: 1em;
      color: var(--ion-color-medium);
      margin-right: 5px;
    }
    .para-price {
      font-size: 1em;
      color: var(--ion-color-dark);
    }
  }
}
.cart-proceed-button {
  padding: 20px 8px 30px;
  ion-button {
    margin: 0;
    // --background: linear-gradient(to right, #var(--ion-color-medium), #var(--ion-color-medium));
    height: 50px;
    width: 100%;
    text-transform: capitalize;
    font-size: 16px;
  }
}
#checkout {
  .order-summary {
    .cartRow {
      --min-height: 30px;
      ion-label {
        margin: 0;
      }
    }
  }
}

/*** ORDERS ***/

.orderCard {
  .orderCardRow {
    --min-height: 30px;
    ion-label {
      margin: 0;
    }
  }
}

/*** PRODUCT CARD ***/
.product-card-content {
  .banner-slides {
    ion-img {
      width: 100%;
    }
  }
  .para-name {
    color: var(--ion-text-color);
  }
  ion-icon {
    font-size: 17px;
  }
  ion-segment {
    ion-segment-button {
      --indicator-color: var(--ion-color-primary);
    }
  }
  ion-button {
    text-transform: capitalize;
    width: 100%;
    --border-radius: 0;
    font-weight: bold;
  }
  ion-grid {
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }
}

/*** NEWS POSTS ***/
.single-post-page {
  --background: var(--ion-background-color) !important;
}

/*** PLAYER PROFILES ***/
.playerProfile {
  .playerAvatar {
    width: 140px;
    max-width: 25%;
    height: auto;
    display: inline-block;
  }
  .content_wrapper {
    padding: 15px 15px 0 0;
  }
  .playerName {
    font-weight: 600;
    font-size: 1.1em;
    white-space: normal;
  }
  .playerStats {
    .spacer {
      margin: 0 5px;
      display: inline-block;
    }
    span {
      color: var(--ion-color-medium);
    }
  }
  .playerInfoBlock {
    background: var(--ion-color-dark);
    color: var(--ion-color-light);
    width: 140px;
    margin: 0;
    text-align: center;
    ion-col {
      padding: 7px;
    }
    span {
      font-weight: 600;
      font-size: 1.1em;
    }
  }
}

.player_cards {
  padding-top: 30px;
  .playerProfileCard {
    padding-bottom: 45px;
    --padding-start: 0px;
    --padding-end: 0px;
    --inner-padding-start: 0px;
    --inner-padding-end: 0px;
    overflow: visible;
    align-items: flex-end;
    .avatar {
      margin-top: -30px;
    }
    .playerInfoBlock {
    }
  }
}

.playerProfilePage {
  .playerImage {
    z-index: 10;
  }
  .playerInfoBlock {
    margin-top: -40px;
    margin-bottom: 30px;
    z-index: 20;
    position: relative;
  }
  .playerName {
    margin-bottom: 0px;
    margin-top: 30px;
  }
  .playerStats {
    margin-bottom: 0px;
    margin-top: 0;
    font-size: 1em;
  }
  .playerDetails {
  }
  ion-item {
    --background: none;
    ion-label {
      font-weight: 500;
    }
  }
  ion-item-divider {
    --background: none;
    --color: var(--ion-color-dark);
  }
}

/*** SCHEDULE ***/
ion-card.matchCard,
ion-card.sliderMatchCard {
  ion-card-content {
    padding-top: 15px;
    padding-inline-start: 15px;
    padding-inline-end: 15px;
    padding-bottom: 15px;
    .teamCol {
      text-align: center;
      .matchScore {
        font-weight: 600;
        color: var(--ion-color-dark);
        font-size: 1.1em;
      }
      .record {
        font-size: 0.8em;
      }
      ion-thumbnail {
        max-width: 100%;
        ion-img {
          display: inline-block;
          max-width: 100%;
          height: auto;
        }
      }
    }
    .matchDetails {
      padding: 5px;
      .matchDate {
        text-transform: uppercase;
        color: var(--ion-color-medium);
        font-size: 0.7em;
      }
      .matchTeams {
        text-transform: uppercase;
        font-weight: 600;
        color: var(--ion-color-dark);
        line-height: 1em;
        font-size: 0.9em;
        padding-bottom: 5px;
      }
      .matchTime {
        text-transform: uppercase;
        color: var(--ion-color-medium);
        font-size: 0.7em;
        padding-bottom: 5px;
      }
      .matchVenue {
        color: var(--ion-color-medium);
        font-size: 0.7em;
        line-height: 1em;
      }
      .matchStatus {
        text-transform: uppercase;
        font-size: 0.8em;
      }
      .inProgress {
        color: var(--ion-color-success);
      }
      .final {
        color: var(--ion-color-danger);
      }
    }
  }
}

ion-content.match-page {
  .matchTeams {
    font-size: 1.3em;
    color: var(--ion-color-dark);
    font-weight: 600;
  }
  .matchDate {
    text-transform: uppercase;
    color: var(--ion-color-dark);
    font-size: 1em;
  }
  .matchVenue {
    color: var(--ion-color-dark);
    font-size: 0.8em;
  }
  .matchStatusCol {
    padding-top: 10px;
    text-align: right;
    .matchStatus.inProgress {
      background-color: var(--ion-color-success);
    }
    .matchStatus.final {
      background-color: var(--ion-color-danger);
    }
  }
  .teamDetails {
    .teamDetailsItem {
      padding-top: 7px;
      padding-bottom: 7px;
      --padding-start: 0px;
      --padding-end: 0px;
      --inner-padding-start: 0px;
      --inner-padding-end: 0px;
      --background: none;
      // .teamIcon {
      // }
      .teamIcon {
        width: 45px;
        height: auto;
        // max-height: 40px;
      }
      .teamName {
        font-weight: 500;
        font-size: 1em;
        text-align: left;
        padding: 0px 5px 0px;
      }
      .teamStats {
        max-width: 100px;
        margin: 0;
      }
      .teamRecord {
        font-size: 0.8em;
        color: var(--ion-color-medium);
        position: absolute;
        top: 25%;
        height: 50%;
      }
      .matchScore {
        font-weight: 600;
        color: var(--ion-color-dark);
        font-size: 1.2em;
        line-height: 1.3em;
      }
    }
  }
  #awayTeamRoster {
    .number {
      font-weight: 600;
      font-size: 1em;
      text-align: left;
    }
    .name {
      font-weight: 500;
      font-size: 1em;
      text-align: left;
    }
    .pos {
      font-weight: 500;
      font-size: 1em;
      text-align: right;
    }
    .header {
      font-weight: 500;
      font-size: 0.8em;
      color: var(--ion-color-light);
      text-align: right;
    }
  }
  .buttons {
    --padding-start: 0px;
    --padding-end: 0px;
    --inner-padding-start: 0px;
    --inner-padding-end: 0px;
    --background: none;
  }
}

/*** LADDER ***/
.ladder-page {
  .ladder {
    th,
    td {
      text-align: right;
      width: 10%;
    }
    .posCol {
      width: 3%;
      padding-right: 5px;
      text-align: right;
    }
    .teamCol {
      text-align: left;
      width: 40%;
      .icon {
        width: 40px;
        height: auto;
        max-height: 40px;
        display: inline-block;
        vertical-align: middle;
        margin: 0 10px;
        ion-thumbnail {
          height: 100%;
          width: 100%;
        }
      }
      .teamName {
        display: inline-block;
        font-weight: 500;
        font-size: 1.1em;
      }
    }
    // .gamesCol {
    // }
    // .winsCol {
    // }
    // .lossCol {
    // }
    .forCol {
      width: 10%;
    }
    .againstCol {
      width: 10%;
    }
    // .pointsCol {
    // }
    .header {
      font-size: 0.7em;
      text-align: center;
    }
    tbody tr {
      height: 40px;
    }
    tbody tr td {
      font-size: 0.7em;
      text-align: center;
    }
  }
  .link_button_wrapper {
    text-align: center;
  }
}

/*** ACTIVATIONS ***/
#activation-page {
  .repeater-card {
    ion-grid.no-padding {
      padding: 0;
    }
    ion-row.no-padding {
      padding: 0;
    }
    ion-col.no-padding {
      padding: 0;
    }
    ion-icon.trash {
      width: 100%;
      height: 20px;
      color: var(--ion-color-danger);
    }
  }
  ion-card h2.activation-section-header {
    font-weight: 500;
  }
}

#activations-module-wrapper {
  ion-card-subtitle {
    font-size: 0.8em;
  }
  ion-card-title {
    font-size: 1.3em;
  }
  .card-wrapper {
    padding: 16px;
    .activationCard::part(image) {
      border: 1px solid #fff0;
      border-radius: 10px;
    }
    .activationCard {
      &.expired {
        filter: grayscale(100%);
        opacity: 0.5;
      }
      &.inactive {
        filter: grayscale(100%);
      }
    }
  }
}

.activationCard {
  .activationActive {
    color: var(--ion-color-light);
    text-align: center;
    padding: 2px;
  }
  &.active {
    .activationActive {
      background-color: var(--ion-color-success);
    }
  }
  &.expired {
    .activationActive {
      background-color: var(--ion-color-medium);
    }
    ion-img {
      filter: grayscale(100%);
    }
    opacity: 0.5;
  }
  &.inactive {
    .activationActive {
      background-color: var(--ion-color-warning);
    }
    ion-img {
      filter: grayscale(100%);
    }
  }
}

/*** REQUESTS ***/
.requestsCard {
  .requestsActive {
    color: var(--ion-color-light);
    text-align: center;
    padding: 2px;
  }
  &.true {
    .requestsActive {
      background-color: var(--ion-color-success);
    }
  }
  &.false {
    .requestsActive {
      background-color: var(--ion-color-warning);
    }
    ion-img {
      filter: grayscale(100%);
    }
  }
  ion-card-subtitle {
    font-size: 0.8em;
  }
  ion-card-title {
    font-size: 1.3em;
  }
}

/*** REQUESTS ***/
.programmeCard {
  ion-card-subtitle {
    font-size: 0.8em;
  }
  ion-card-title {
    font-size: 1.3em;
  }
}

/*** PROMOTIONS ***/
.promo-card {
  ion-card-subtitle {
    font-size: 0.8em;
  }
  ion-card-title {
    font-size: 1.3em;
  }
}

/*** MESSAGES ***/
.message-feed .message-card {
  ion-card-subtitle {
    font-size: 0.8em;
  }
  ion-card-title {
    font-size: 1.3em;
  }
}

.messagesCard {
  .messagesActive {
    color: var(--ion-color-light);
    text-align: center;
    padding: 2px;
  }
  &.true {
    .messagesActive {
      background-color: var(--ion-color-success);
    }
  }
  &.false {
    .messagesActive {
      background-color: var(--ion-color-warning);
    }
    ion-img {
      filter: grayscale(100%);
    }
  }
  ion-card-subtitle {
    font-size: 0.8em;
  }
  ion-card-title {
    font-size: 1.3em;
  }
}

.admin-message-card {
  .playlist-index {
    position: absolute;
    top: 10px;
    z-index: 10;
    right: 10px;
    font-size: 2em;
    font-weight: 600;
    color: black;
    background-color: #ffffffba;
    padding: 10px;
    border-radius: 4px;
  }
}

/*** DASHBOARD ***/
/* SECTIONS */
.section-header {
  font-family: "Permanent Marker", sans-serif;
  text-transform: uppercase;
  text-align: center;
  color: var(--ion-color-primary);
  font-size: 2.3em;
  margin-bottom: 0;
}

/* SHOP MODULE */
.shop-dashboard-wrapper {
  background: url("https://firebasestorage.googleapis.com/v0/b/rams-app-22bcc.appspot.com/o/static-resources%2Fimagery%2Fgrunge_brick-bg.png?alt=media&token=8b21ed72-0f2d-4849-8499-635146e76fa1");
  margin: -16px -16px 30px;
  padding: 120px 16px 150px 16px;
  background-size: cover;
  background-repeat: no-repeat;
  .section-header {
    color: white;
  }
}

/* MESSAGES */
.messages-dashboard-section {
  p {
    font-size: 1.2em;
    font-weight: 400;
    text-align: center;
  }
}

/* REQUESTS */
.requests-dashboard-wrapper {
  //background: url("https://firebasestorage.googleapis.com/v0/b/rams-app-22bcc.appspot.com/o/static-resources%2Fimagery%2Fgrunge_music-bg.png?alt=media&token=b1510829-6749-4c35-a89d-5e2cb9e351c0");
  background-position: center center;
  margin: 30px -16px 30px -16px;
  padding: 280px 16px 150px 16px;
  background-size: cover;
  background-repeat: no-repeat;
  .section-header {
    color: white;
  }
}

/* MATCH PROGRAMME */
.current-dashboard-section {
  // background: url("https://firebasestorage.googleapis.com/v0/b/rams-app-22bcc.appspot.com/o/static-resources%2Fimagery%2Fgrunge_cowles_red.png?alt=media&token=45c20942-6241-4bcf-bf2c-ab3fa24b3988");
  background-position: center center;
  margin: 30px -16px 30px -16px;
  padding: 150px 16px 150px 16px;
  background-size: cover;
  background-repeat: no-repeat;
  .section-header {
    color: white;
  }
  p {
    font-size: 1.2em;
    font-weight: 400;
    text-align: center;
    color: white;
  }
}

/* SCHEDULE LADDER */
.dashboard #scheduleLadder {
  .schedule-section {
    // background: url("https://firebasestorage.googleapis.com/v0/b/rams-app-22bcc.appspot.com/o/static-resources%2Fimagery%2Fgrunge_activations-bg.png?alt=media&token=81df84e2-eb5a-4621-9e72-9228840d8c78");
    background-position: center center;
    margin: 30px -16px 30px -16px;
    padding: 150px 16px 150px 16px;
    background-size: cover;
    background-repeat: no-repeat;
    .section-header {
      color: white;
    }
    p {
      font-size: 1.2em;
      font-weight: 400;
      text-align: center;
      color: white;
    }
    .schedule-segment {
      ion-segment-button {
        --color: white;
        &.ios {
          --color-checked: black;
        }
      }
    }
  }
  .activation-card::part(image) {
    border: 1px solid #fff0;
    border-radius: 10px;
  }
  .ladder-section {
    background: url("https://firebasestorage.googleapis.com/v0/b/rams-app-22bcc.appspot.com/o/static-resources%2Fimagery%2Fgrunge_bricks-tall-bg.png?alt=media&token=3718ecfd-5a49-47ad-a032-9cf848b8e41a");
    background-position: center center;
    margin: 30px -16px 30px -16px;
    padding: 150px 16px 150px 16px;
    background-size: cover;
    background-repeat: no-repeat;
    .section-header {
      color: white;
    }
  }
}

/* NEWS SLIDER */
.post-card {
  ion-card-subtitle {
    font-size: 0.8em;
  }
  ion-card-title {
    font-size: 1.3em;
  }
}

ion-slides.news-slider {
  width: 100%;
  height: 100%;
  padding-bottom: 30px;
  .swiper-slide {
    text-align: center;
    font-size: 18px;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    width: 90%;
    .swiper-slide:nth-child(2n) {
      width: 40%;
    }

    .swiper-slide:nth-child(3n) {
      width: 20%;
    }
    ion-card {
      width: 100%;
      text-align: left;
      .card-date {
        font-size: 0.9em;
      }
      .card-title {
        font-size: 1.2em;
      }
    }
  }
}

/* SCHEDULE SLIDER */
ion-slides.match-slider {
  width: 100%;
  height: 100%;
  padding-bottom: 30px;
  .swiper-slide {
    font-size: 18px;
    //background: #fff;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    width: 90%;
    .swiper-slide:nth-child(2n) {
      width: 40%;
    }

    .swiper-slide:nth-child(3n) {
      width: 20%;
    }
    ion-card.sliderMatchCard {
      width: 100%;
      text-align: left;
      .date-row-wrapper {
        background-color: var(--ion-color-primary);
        color: #ffffff;
        font-weight: 500;
      }
      ion-button {
        margin: 0 auto;
      }
    }
  }
}

/* SHOP */
.shop-page {
  .subsection-header {
    font-family: "SF Collegiate Solid", sans-serif;
    text-transform: uppercase;
    text-align: center;
    color: var(--ion-color-secondary);
    font-size: 1.8em;
    margin-bottom: 0;
  }
}
.productCard {
  .action-row {
    .qty-col {
      // padding-left: 30px;
      ion-input {
        --padding-end: 0px;
        text-align: right;
      }
    }
    .button-col {
      text-align: right;
      .add-button {
        height: 100%;
        width: 100%;
      }
    }
  }
}
#checkout.ion-page {
  #cardForm {
    .payment-method .card-brand {
      text-transform: capitalize;
    }
    .error-message {
      color: var(--ion-color-danger);
    }
  }
  .seat-error {
    color: var(--ion-color-danger);
  }
}

.shopDashboardCard {
  ion-card-subtitle {
    font-size: 0.8em;
  }
  ion-card-title {
    font-size: 1.3em;
  }
}

/*** FULL PAGE SLIDER ***/
.fullpage-slider {
  height: 100%;
  .slider-container {
    height: 100%;
    .slider {
      height: 100%;
      .react-slideshow-container {
        height: 100%;
      }
      .react-slideshow-fade-wrapper {
        height: 100%;
      }
      .react-slideshow-fade-images-wrap {
        height: 100%;
      }
      .slide-wrapper.customImage {
        height: 100%;
        background-color: black;
        .bg-image {
          height: 100%;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: 50% 50%;
          filter: blur(8px);
          -webkit-filter: blur(8px);
          .bg-overlay {
            height: 100%;
            background-color: rgba(0, 0, 0, 0.65);
          }
        }
        .slide {
          position: absolute;
          top: 0;
          height: 100%;
          width: 100%;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: 50% 50%;
          display: flex;
          align-items: flex-end;
          .content-wrapper {
            flex-grow: 1;
            .content {
              background-color: rgba(0, 0, 0, 0.65);
              padding: 60px 120px;
              color: white;
              flex-grow: 1;
              text-align: center;
              .message {
                font-size: 6em;
                display: block;
                margin-bottom: 30px;
              }

              .row.name.votes {
                display: flex;
                .name-wrapper {
                  flex-grow: 8;
                  flex-basis: auto;
                  flex-shrink: 6;
                  .name {
                    font-size: 4em;
                    font-weight: 600;
                  }
                }
                .votes-wrapper {
                  flex-grow: 4;
                  flex-basis: auto;
                  display: flex;
                  justify-content: flex-end;
                  .icon {
                    font-size: 2em;
                    color: #b62025;
                  }
                  .votes {
                    padding-left: 10px;
                    font-size: 1.7em;
                  }
                }
              }
            }
          }
        }
      }
      .slide-wrapper.defaultImage {
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        .slide {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          .content-wrapper {
            padding: 120px;
            .content {
              width: auto;
              height: auto;
              background-color: rgba(0, 0, 0, 0.65);
              padding: 60px 120px;
              color: white;
              text-align: center;

              .message {
                font-size: 6em;
                display: block;
                margin-bottom: 30px;
              }

              .row.name.votes {
                display: flex;
                .name-wrapper {
                  flex-grow: 8;
                  flex-basis: auto;
                  flex-shrink: 6;
                  .name {
                    font-size: 4em;
                    font-weight: 600;
                  }
                }
                .votes-wrapper {
                  flex-grow: 4;
                  flex-basis: auto;
                  display: flex;
                  justify-content: flex-end;
                  .icon {
                    font-size: 2em;
                    color: #b62025;
                  }
                  .votes {
                    padding-left: 10px;
                    font-size: 1.7em;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

/*** FULL PAGE SLIDER ***/
.outputScreen {
  height: 100%;
  .slider-container {
    height: 100%;

    .slide-wrapper {
      &.customImage {
        height: 100%;
        background-color: black;
        .bg-image {
          height: 100%;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: 50% 50%;
          filter: blur(8px);
          -webkit-filter: blur(8px);
          .bg-overlay {
            height: 100%;
            background-color: rgba(0, 0, 0, 0.65);
          }
        }
        .slide {
          position: absolute;
          top: 0;
          height: 100%;
          width: 100%;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: 50% 50%;
          display: flex;
          align-items: flex-end;
          .content-wrapper {
            flex-grow: 1;
            .content {
              background-color: rgba(0, 0, 0, 0.65);
              padding: 60px 120px;
              color: white;
              flex-grow: 1;
              text-align: center;
              .message {
                font-size: 3em;
                display: block;
                margin-bottom: 30px;
              }

              .row.name.votes {
                display: flex;
                .name-wrapper {
                  flex-grow: 8;
                  flex-basis: auto;
                  flex-shrink: 6;
                  .name {
                    font-size: 3em;
                    font-weight: 600;
                  }
                }
                .votes-wrapper {
                  flex-grow: 4;
                  flex-basis: auto;
                  display: flex;
                  justify-content: flex-end;
                  .icon {
                    font-size: 2em;
                    color: #b62025;
                  }
                  .votes {
                    padding-left: 10px;
                    font-size: 1.7em;
                  }
                }
              }
            }
          }
        }
      }
      &.defaultImage {
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        .slide {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          .content-wrapper {
            padding: 120px 180px;
            .content {
              width: auto;
              height: auto;
              background-color: rgba(0, 0, 0, 0.65);
              padding: 60px 120px;
              color: white;
              text-align: center;

              .message {
                font-size: 4em;
                display: block;
                margin-bottom: 30px;
              }

              .row.name.votes {
                display: flex;
                .name-wrapper {
                  flex-grow: 8;
                  flex-basis: auto;
                  flex-shrink: 6;
                  .name {
                    font-size: 4em;
                    font-weight: 600;
                  }
                }
              }
            }
          }
        }
      }
      &.request {
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        .slide {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          .content-wrapper {
            padding: 170px 110px 130px 420px;
            .content {
              width: auto;
              height: auto;
              background-color: rgba(0, 0, 0, 0.65);
              padding: 60px 120px;
              color: white;
              text-align: center;
              border-radius: 30px;
              .title {
                // color: var(--ion-color-primary);
                // font-family: "SF Collegiate Solid", sans-serif;
                // text-transform: uppercase;
                // text-align: center;
                // color: var(--ion-color-primary);
                // font-size: 6em;
                // margin: 0;
                // line-height: 0.8em;
                max-width: 800px;
                margin-top: -220px;
              }
              .name {
                font-size: 6em;
                font-weight: 600;
                margin: 0;
                line-height: 0.9em;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                padding: 10px;
              }
              .song {
                font-size: 3em;
                margin: 0;
                line-height: 0.8em;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                padding: 10px;
              }
              .message {
                font-size: 4.5em;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                padding: 10px;
              }

              .row.name.votes {
                display: flex;
                .name-wrapper {
                  flex-grow: 8;
                  flex-basis: auto;
                  flex-shrink: 6;
                  .name {
                    font-size: 4em;
                    font-weight: 600;
                  }
                }
              }
            }
          }
        }
      }
      &.results {
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        width: 100%;
        .slide {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          .content-wrapper {
            width: 100%;
            height: 100%;
            display: flex;
            .content {
              width: 100%;
              .results-page {
                width: 100%;
                .results-wrapper {
                  width: 100%;
                }
              }
            }
          }
        }
      }
      &.submission {
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        width: 100%;
        .slide {
          height: 100%;
          display: flex;
          align-items: flex-end;
          .content-wrapper {
            width: 100%;
            padding: 0;
            .content {
              width: 100%;
              .message {
                font-size: 4em;
              }
            }
          }
        }
      }
    }
    .overlay {
      position: absolute;
      top: 0;
    }
  }
}

/*** ACTIVATION RESULTS ***/

/* RACE CHART */
.results-page {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
  --background: none;
  .results-wrapper.vote {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &.layout-center {
      // align-items: center;
      padding-top: 25%;
      .results {
        width: 80%;
      }
    }
    &.layout-left {
      // align-items: flex-start;
      .results {
        width: 65%;
      }
    }
    &.layout-right {
      // align-items: flex-end;
      .results {
        width: 65%;
      }
    }
    .results {
      display: flex;
      flex-direction: column;

      .pageTitle {
        font-weight: 600;
        font-size: 3.5em;
      }
      .pageSubtitle {
        margin: 0;
      }
      .raceChart {
        margin-right: 150px;
        .raceArea {
          margin: 0 auto;
          .raceItem span {
            width: 430px;
            flex-direction: row;
          }
        }
      }
      .matchResult {
        width: 600px;
        .teamName {
          font-weight: 600;
          font-size: 4em;
          text-align: center;
          width: 100%;
          display: block;
        }
        .score {
          font-weight: 400;
          font-size: 4em;
          width: 100%;
          text-align: center;
          display: inline-block;
        }
      }
      .winners-wrapper {
        width: 90%;
        justify-content: center;
        .card {
          .cardContent {
            .name {
              font-weight: 600;
              font-size: 2.5em;
              text-align: center;
            }
            .answer {
              font-size: 1.7em;
              text-align: center;
            }
            .teamCol {
              .teamName {
                font-weight: 600;
                font-size: 2em;
                text-align: center;
                width: 100%;
                display: block;
              }
              .result {
                text-align: center;
                width: 100%;
                .pick {
                  font-weight: 400;
                  font-size: 1.7em;
                }
                .diff {
                  font-weight: 300;
                  font-size: 1.5em;
                  color: rgb(88, 88, 88);
                }
              }
            }
            .totalCol {
              .diff {
                font-weight: 600;
                font-size: 2.2em;
                text-align: center;
                width: 100%;
                display: block;
              }
            }
          }
        }
      }
    }
  }
}
/* SCORE */
.results-page {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
  --background: none;
  height: 100%;
  padding: 0;
  .results-wrapper.score {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 20%;
    height: 100%;
    .results {
      width: 100%;
      height: 100%;
      .scorebox-wrapper {
        width: 600px;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        .scorebox {
          flex-grow: 1;
          background-color: white;
          text-align: center;
          width: 400px;
          margin: 15px;
          padding: 60px 20px;
          height: auto;
          border-radius: 20px;
          border: 1px white solid;
          .actualScore {
            .teamName {
              font-size: 2em;
              font-weight: 300;
              text-transform: uppercase;
            }
            .teamScore {
              font-size: 6em;
              font-weight: 600;
              line-height: 0.9em;
            }
          }
        }
      }
      .winners-wrapper {
        width: 1200px;
        margin: 30px auto 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        .winnersTitle {
          font-size: 3em;
          font-weight: 600;
          line-height: 0.9em;
          color: white;
        }
        .winner {
          flex-grow: 1;
          display: flex;
          flex-direction: row;
          align-items: center;
          align-content: flex-end;
          vertical-align: bottom;
          background-color: white;
          margin: 15px;
          height: auto;
          border-radius: 20px;
          border: 1px white solid;
          .name {
            flex-grow: 1;
            font-size: 3em;
            font-weight: 600;
            line-height: 0.9em;
            align-self: flex-end;
            padding: 30px;
          }
          .winnerScore {
            flex-grow: 1;
            padding: 30px;
            .subtitle {
              text-align: center;
              font-size: 1.5em;
              font-weight: 300;
              text-transform: uppercase;
            }
            .score {
              text-align: center;
              font-size: 3em;
              font-weight: 600;
              line-height: 0.9em;
            }
            .difference {
              text-align: center;
              font-size: 3em;
              font-weight: 600;
              line-height: 0.9em;
            }
          }
        }
      }
    }
  }
}
/* VOTE */
.results-page {
  display: flex;
  flex-direction: row;
  align-items: center;
  // background-size: cover;
  // background-repeat: no-repeat;
  // background-position: center;
  // --background: none;
  height: 100%;
  padding: 0;
  .headerImage {
    position: absolute;
    top: 0;
    width: 100%;
    height: 600px;
    background-size: contain;
    background-position: top center;
    background-repeat: no-repeat;
  }
  .results-wrapper.vote {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 25%;
    height: 100%;
    .results {
      width: 100%;
      height: 100%;
      .winners-wrapper {
        width: 100%;
        margin: 30px auto 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        .winnersTitle {
          font-size: 3em;
          font-weight: 400;
          line-height: 0.9em;
        }
        .winner {
          flex-grow: 1;
          display: flex;
          flex-direction: row;
          align-items: center;
          align-content: flex-end;
          vertical-align: bottom;
          background-color: white;
          margin: 15px;
          height: auto;
          border-radius: 20px;
          border: 1px white solid;
          color: black;
          .name {
            flex-grow: 1;
            font-size: 5em;
            font-weight: 600;
            line-height: 0.9em;
            align-self: flex-end;
            padding: 30px;
          }
        }
      }
    }
  }
  .footerImage {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 240px;
    background-size: contain;
    background-position: bottom center;
    background-repeat: no-repeat;
  }
}

/*** ADMIN ORDERS ***/
.orderCard {
  ion-list.cartItemsList {
    padding: 0;
    .item.cartItem {
      --min-height: 1em;
      font-size: 14px;
    }
  }
}

/*** IMAGE UPLOADER ***/
.imageUploader .uploadedImage {
  max-width: 100px;
}
